// @codekit-prepend "_base.js";

// implementing zenscrolliing inside Case Study Modals
var caseModalHeader;
var caseModalLinks;
// get all Case Study Modals from the DOM, and iterate through them:
var caseModals = document.querySelectorAll("div[id^=case-modal]");
Array.prototype.forEach.call(caseModals, function(modal){
    // for each modal window, get the header and the body:
    caseModalHeader = modal.querySelector('div.uk-modal-header');
    // hijack the click events for all header links, to use the zenscroller:
    caseModalLinks = caseModalHeader.querySelectorAll('a[href^=\\#case-modal]');
    Array.prototype.forEach.call(caseModalLinks, function(link){
        link.addEventListener('click',scrollToTarget);
    });
});

function scrollToTarget(event){
    event.preventDefault();
    event.stopPropagation();
    var link = event.currentTarget; // the clicked link
    var target = document.getElementById(link.getAttribute('href').substring(1)); // the scroll target
    var container = target.parentNode; // the scrollable container
    defaultDuration = 500;
    edgeOffset = 70;
    var scroller = zenscroll.createScroller(container, defaultDuration, edgeOffset);
    scroller.to(target);
}

// disabling zenscrolling inside UIKit dot-navs:
var dotLinks = document.querySelectorAll(".uk-dotnav a");
Array.prototype.forEach.call(dotLinks, function(dotItem){
    dotItem.classList.add('noZensmooth');
});

// AJAX CONTACT FORM PROCESSING - needs jQuery
$('form.contact-form').on('submit', function(e){
    e.preventDefault();
    var form = $(this);
    // hide any alert messages currently showing
    form.find('.uk-alert').addClass('uk-hidden');
    $.ajax({
        type: 'POST',
        // use the same url here as the 'pattern' in our route
        url: 'api/form',
        data: form.serialize(),
        success: function(result){
            // form data successfully reached form processor api
            if(result.success){
                // message successfully sent
                form.find('.uk-alert-primary').removeClass('uk-hidden');
            } else {
                // an issue was encountered
                form.find('.uk-alert-danger').removeClass('uk-hidden');
            }
        },
        error: function(){
            // the form was unable to reach processor api
            form.find('.uk-alert-danger').removeClass('uk-hidden');
        },
        dataType: 'json' 
    });
});

// overriding UIKit's default 'close button' behaviour - it conflicts:
$('form.contact-form button.uk-alert-close').click(function(e){
    e.preventDefault();
    $(this).parent().addClass('uk-hidden');
});
